/**
 * AVOID global and cascading styles; ONLY ADD CLASSES UNDER SPECIAL CIRCUMSTANCES
 * after those circumstances have been discussed with team.
 */

html {
  @apply h-full;
}

body {
  @apply font-sans flex flex-col min-h-screen;
}
