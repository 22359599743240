/**
 * ONLY ADD CLASSES UNDER SPECIAL CIRCUMSTANCES
 * after those circumstances have been discussed with team
 */

/**
 * Positions custom chevon on select, this was a
 * compromise to building a full on custom select
 * dropdown component
 */
select:not(select--multiple) {
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  &::-ms-expand {
    display: none;
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='%2342484d' d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'></path></svg>");
  @apply h-3.5 w-3.5 cursor-pointer;
}

mark {
  @apply font-bold bg-transparent text-inherit;
}

/**
 * Google Recaptcha - Hide badge
 */
.grecaptcha-badge {
  visibility: hidden;
}

/**
 * Rich Text Spacing
 */
.rich-text {
  /* NOTE: Order matters */

  /* Base spacing #1 */
  > p {
    @apply first:mt-0 mt-7;
  }

  > ul,
  > ol {
    /* Base spacing 28px - 12px = 16px; this accounts for the 12px of li padding top*/
    @apply first:mt-0 mt-4;
  }

  & > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > hr,
  > .embedded-entry {
    @apply first:mt-0;
  }

  /* heading following anything #2 */
  & > * + h1,
  > * + h2,
  > * + h3,
  > * + h4,
  > * + h5,
  > * + h6 {
    @apply mt-12 lg:mt-15;
  }

  /* hr following anything #3 */
  & > * + hr {
    @apply mt-12 lg:mt-15;
  }

  /* embedded entry following anything #4 */
  & > * + .embedded-entry {
    @apply mt-8;
  }

  /* paragrah following anything #6, this rule needs to be before 5 so 5 more specific rule wins */
  & > * + p {
    @apply mt-7;
  }

  /* paragraph follows an embedded entry #5 */
  & > .embedded-entry + p {
    @apply mt-8;
  }

  /* paragraph follows an hr */
  & > hr + p {
    @apply mt-12 lg:mt-15;
  }
}

/**
 * Rich Text List styles
 */
.rich-text {
  & ::marker {
    font-weight: inherit;
  }

  & ul,
  ol {
    @apply ml-5;
  }

  /* List styles type (3 levels deep) */
  & ul {
    @apply list-disc;
    & ul {
      @apply list-[circle];
      & ul {
        @apply list-[square];
      }
    }
  }

  & ol {
    @apply list-decimal;
    & ol {
      @apply list-[lower-alpha];
      & ol {
        @apply list-[lower-roman];
      }
    }
  }

  /* List columns */
  @media (min-width: theme('screens.lg')) {
    &.list-column-2 {
      & > ul,
      > ol {
        @apply columns-2 gap-15;
      }
    }

    &.list-column-3 {
      & > ul,
      > ol {
        @apply columns-3 gap-15;
      }
    }
  }

  /* List alignment */
  &.list-center {
    & > ul,
    > ol {
      @apply inline-block text-left;
    }
  }

  /* List item */
  & li {
    @apply pt-3 break-inside-avoid-column;
  }

  & li > p {
    @apply inline;
  }
}

/* I don't always nest containers, but when I do, I don't add extra padding */
.container .container {
  @apply px-0;
}

.caption > p {
  @apply inline;
}

/* sets whether lines of text are laid out horizontally or vertically - lr means vertical */
.text-vertical-lr {
  writing-mode: vertical-lr;
}

/* 0verriding rich text stylings for campaign media carousel */
.campaign-carousel-rich-text {
  & a {
    @apply text-white hover:text-white;
  }
}

/* 0verriding rich text stylings for campaign */
.campaign-rich-text {
  & a {
    @apply text-campaign-tertiary-700 hover:text-campaign-tertiary-800;
  }

  & h2 {
    @apply text-campaign-tertiary-700;
  }

  & h3 {
    @apply text-campaign-tertiary-700;
  }

  & p {
    @apply text-campaign-secondary-800;
  }
}

/* 0verriding rich text stylings for directory boards */
.rich-text-xl {
  & p {
    @apply text-[42px] leading-[56px];
  }

  & h1 {
    @apply text-[60px] font-semibold leading-[80px];
  }

  & h2 {
    @apply text-6xl font-semibold;
  }

  & h3 {
    @apply text-[36px] font-medium leading-11;
  }

  & ul,
  ol {
    @apply ml-15;
  }

  & strong {
    @apply font-semibold;
  }
}

.feathery-form {
  [type="text"]:focus, input:where(:not([type])):focus,
  [type="tel"]:focus, [type="email"]:focus, [multiple]:focus, textarea:focus, select:focus {
    --tw-ring-color: theme(colors.primary.DEFAULT);
    border-color: theme(colors.primary.DEFAULT);
  }

  [type="text"]:active, input:where(:not([type])):active, [type="tel"]:active, [type="email"]:active,
  [multiple]:active, textarea:active, select:active {
    --tw-ring-color: theme(colors.primary.DEFAULT);
    border-color: theme(colors.primary.DEFAULT);
  }

  .inner-container > span {
    color: theme(colors.danger);
  }
}
